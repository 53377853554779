<template>
  <div ref="container" class="newsContainer">
    <div class="title">需求列表</div>
    <div class="tableContainer">
      <div class="addWrap">
        <el-button class="add" @click.stop="down">下载表格</el-button>
      </div>

      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              label="姓名"
              min-width="90"
              prop="name"
              align="center"
            />
            <el-table-column
              label="电话"
              min-width="120"
              prop="mobile"
              align="center"
            />
            <el-table-column
              label="身份"
              min-width="90"
              prop="identity"
              align="center"
            />
            <el-table-column
              label="其他身份"
              min-width="90"
              prop="otherIdentity"
              align="center"
            />
            <el-table-column
              label="创建时间"
              min-width="190"
              prop="createTime"
              align="center"
            />
            <el-table-column
              label="查看"
              prop="demand"
              min-width="142"
              align="center"
            >
              <!-- <template slot-scope="scope">
                <span :style="scope.row.readFlag===1?'':'cursor:pointer;'" class="editCommon" @click="handleEdit(scope.row)">{{ scope.row.readFlag===1?'已读':'未读' }}</span>
              </template> -->
              <template slot-scope="scope">
                <!-- <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button> -->
                <span
                  class="deleteCommon"
                  @click.stop="handShow(scope.$index, scope.row, 1)"
                  >查看</span
                >
                <el-dialog
                  :visible.sync="dialogVisible"
                  title="需求信息"
                  width="30%"
                  class="popUp"
                >
                  <div class="dialog-content">姓名：{{ tableRow.name }}</div>
                  <div class="dialog-content">电话：{{ tableRow.mobile }}</div>
                  <div class="dialog-content">
                    所属身份：{{ tableRow.identity }}
                  </div>
                  <div class="dialog-content">
                    其他身份：{{ tableRow.otherIdentity }}
                  </div>
                  <div class="dialog-content">需求：{{ tableRow.demand }}</div>
                </el-dialog>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="demandDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getDemandDockingList } from "@/apis/user";
import { parseTime } from "@/utils/index";
import { getCookie } from "@/utils/auth";
export default {
  data() {
    return {
      // tableHeight: '',
      tableData: [],
      current: 1,
      demandDetail: "",
      position: ["其他", "政府部门", "项目企业", "投资机构"],
      dialogVisible: false,
      demand: "",
      tableRow: "",
    };
  },
  activated() {
    // this.tableHeight = this.$refs.container.offsetHeight - 240
    this.getDemandDockingList(this.current);
  },
  methods: {
    getDemandDockingList(current = 1) {
      getDemandDockingList({ current }).then((res) => {
        var records = res.result.records;
        records.forEach((item) => {
          item.createTime = parseTime(item.createTime);
          if (item.otherIdentity === "") {
            item.otherIdentity = "无";
          }
          item.identity = this.position[item.identity];
        });
        this.tableData = records;
        this.demandDetail = res.result;
      });
    },
    down() {
      var Auth = getCookie("Auth");
      window.location.href = `https://api.joinzs.com/mpgenerator/gov/downloadDemandDocking4Csv?&auth=${Auth}`;
    },
    handShow(index, row) {
      this.dialogVisible = true;
      this.demand = row.demand;
      this.tableRow = row;
    },
    // 页码切换
    clickPage(e) {
      this.current = e;
      this.getDemandDockingList(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.newsContainer {
  // height:calc(100vh - 118px);
  // position:relative;
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
  }
  .tableContainer {
    overflow: hidden;
    // width:100%;
    // min-height:440px;
    border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .addWrap {
      overflow: hidden;
    }
    .add {
      float: left;
      margin: 12px 0 0 22px;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
  .dialog-content {
    display: flex;
    width: 100%;
    height: auto;
    white-space: pre-wrap !important;
    text-align: left;
  }
}
</style>
